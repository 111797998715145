import { Component, Prop } from 'vue-property-decorator'
import jsPDF from 'jspdf'
import { GForm } from '@/components/forms/GForm'
import axios from 'axios'

const baseURl = process.env.VUE_APP_BASE_URL

@Component
export class GPdf extends GForm {
  @Prop({ type: [Object, Array, Number, String] }) readonly item!: any;
  @Prop({ type: Object, required: false }) readonly options!: any;
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean;

  setFont (doc) {
    this.setDefaultFont(doc)
  }

  setDefaultFont (doc: jsPDF) {
    doc.setFont(undefined, 'normal')
    doc.setFontSize(12)
    doc.setTextColor(115, 115, 115)
  }

  addDate (doc: jsPDF, x: number, y: number, date): number {
    // Agregar texto de fecha primero
    doc.setTextColor(115, 115, 115)
    doc.setFontSize(12)
    doc.text(date, x, y)
    return y
  }

  separator (doc: jsPDF, y: number) {
    doc.setLineWidth(0.3)
    doc.setDrawColor(245, 245, 245)
    doc.line(10, y, doc.internal.pageSize.getWidth() - 10, y)
    return y + 10
  }

  addDotedTitle (doc: jsPDF, x, y, text) {
    doc.setFont(undefined, 'bold')
    doc.text(`\u2022 ${text}`, x, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  footer (doc: jsPDF) {
    doc.setTextColor(97, 133, 219)
    doc.setFontSize(12)
    const height = doc.internal.pageSize.getHeight() - 5
    doc.text('Powered by', this.getCenterWidth(doc, 'Powered by') - 8, height)
    doc.addImage(require('@/assets/genio/Logo.jpg'), 'JPEG', this.getCenterWidth(doc, 'Powered by') + 15.5, height - 4.5, 15, 5.5)
    this.setDefaultFont(doc)
  }

  addClosing (doc, left: string[], right: string[]) {
    let y = doc.internal.pageSize.getHeight() - 25
    left.forEach((item, index) => {
      doc.setFontSize(10)
      if (index === 0) {
        doc.setFont(undefined, 'bold')
      }
      doc.text(item, 15, y)
      this.setDefaultFont(doc)
      y += 5
    })
    y = doc.internal.pageSize.getHeight() - 20
    right.forEach((item, index) => {
      doc.setFontSize(10)
      if (index === 0) {
        doc.setFont(undefined, 'bold')
      }
      doc.text(item, this.getAlignEnd(doc, item), y)
      this.setDefaultFont(doc)
      y += 5
    })
  }

  addSectionTitle (doc: jsPDF, x, y, text) {
    if (y > doc.internal.pageSize.getHeight() - 30) {
      doc.addPage('a4', 'p')
      y = 15
      this.footer(doc)
    }
    doc.setFont(undefined, 'bold')
    doc.setFontSize(16)
    doc.text(text, x, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  newPage (doc: jsPDF, y) {
    doc.addPage('a4', 'p')
    this.footer(doc)
    return 15
  }

  insertNumberedList (doc: jsPDF, x, y, list: string[]) {
    list.forEach((item, index) => {
      y = this.addParagraph(doc, x, y, `${index + 1}. ${item}`)
    })
    return y
  }

  insertList (doc: jsPDF, x, y, list: string[], values: string[]) {
    list.forEach((item, index) => {
      const stringModifiers = {
        noBullet: false,
        bold: false,
      }
      if (item.split('|').length > 1) {
        const modifiers = item.split('|')[1].split('/')
        stringModifiers.noBullet = modifiers.includes('no-bullet')

        item = item.split('|')[0]
      }
      const words = item.split(' ')
      let newLine = stringModifiers.noBullet ? '' : '\u2022 '
      let testNewLine = newLine + words[0] + ' '
      words.forEach((word, index) => {
        if (doc.getTextWidth(testNewLine) < doc.internal.pageSize.getWidth() - 40) {
          newLine += word + ' '
          testNewLine += words[index + 1]
        } else {
          doc.text(newLine, x, y)
          y += 7
          newLine = word + ' '
          testNewLine = words[index + 1] + ' '
        }
      })
      doc.text(String(newLine || ''), x, y)
      if (values[index]) {
        if (values[index].split('|').length > 1) {
          const modifiers = values[index].split('|')[1].split('/')
          stringModifiers.bold = modifiers.includes('bold')

          values[index] = values[index].split('|')[0]
        }
        if (stringModifiers.bold) {
          doc.setFont(undefined, 'bold')
        }
        doc.text(String(values[index] || ''), this.getAlignEnd(doc, values[index] || ''), y)
        this.setDefaultFont(doc)
      }
      if (y >= doc.internal.pageSize.getHeight() - 40) {
        doc.addPage()
        this.footer(doc)
        y = 15
      } else {
        y += 7
      }
    })
    return y
  }

  getCenterWidth (doc: jsPDF, text): number {
    return (doc.internal.pageSize.getWidth() - doc.getTextWidth(text)) / 2
  }

  getAlignEnd (doc: jsPDF, text): number {
    return doc.internal.pageSize.getWidth() - doc.getTextWidth(text) - 10
  }

  addTitle (doc: jsPDF, y: number, title: string): number {
    doc.setFontSize(24)
    doc.setFont(undefined, 'bold')
    doc.setTextColor(0, 51, 165)
    doc.text(title, (doc.internal.pageSize.getWidth() - doc.getTextWidth(title)) / 2, y)
    this.setDefaultFont(doc)
    return y + 7
  }

  addImage (doc: jsPDF, imageData: string, x: number, y: number): number {
    // Alinear la imagen a la derecha con el tamaño especificado
    const imgWidth = 80 // Ancho de la imagen
    const imgHeight = 8 // Alto de la imagen
    const pageWidth = doc.internal.pageSize.getWidth()
    const xPosition = pageWidth - imgWidth - 10 // 10 es el margen derecho

    // Agregar imagen al PDF
    doc.addImage(imageData, 'PNG', xPosition, y, imgWidth, imgHeight)
    return y + imgHeight
  }

  addParagraph (doc: jsPDF, x: number, y: number, paragraph: string): number {
    const words = paragraph.split(' ')
    let newLine = ''
    words.forEach(word => {
      if (doc.getTextWidth(newLine) < doc.internal.pageSize.getWidth() - 40) {
        newLine += word + ' '
      } else {
        doc.text(newLine, x, y)
        y += 7
        newLine = word + ' '
      }
    })
    doc.text(newLine, x, y)
    y += 7
    return y
  }

  addSeparator (doc: jsPDF, x: number, y: number): number {
    const pageWidth = doc.internal.pageSize.getWidth()
    doc.setLineWidth(0.3)
    doc.setDrawColor(140) // Color gris claro
    doc.line(x, y, pageWidth - x, y)
    return y + 5 // Retorna la nueva coordenada y después de la línea
  }

  addTextCenter (doc: jsPDF, parts: {
    text: string
    style: string
  }[], x: number, y: number, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5): number {
    const pageWidth = doc.internal.pageSize.getWidth() - 15
    doc.setFontSize(12)

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)
      lines.forEach((line, index) => {
        // Aplicar el margen solo en la primera línea del párrafo
        const currentX = index === 0 ? line.x + addMargin : line.x
        doc.text(line.text, currentX, line.y, { align: 'center' })
        x = line.x + doc.getTextWidth(line.text)
        y = line.y
        if (x > pageWidth) {
          x = 10
          y += 5
        }
        if (addNextLine) {
          x = 10
          y += 5
        }
      })
      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  addTextParts (doc: jsPDF, parts: {
    text: string
    style: string
  }[], x: number, y: number, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5): number {
    const pageWidth = doc.internal.pageSize.getWidth() - 15
    doc.setFontSize(12)

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)
      lines.forEach((line, index) => {
        // Aplicar el margen solo en la primera línea del párrafo
        const currentX = index === 0 ? line.x + addMargin : line.x
        doc.text(line.text, currentX, line.y)
        x = line.x + doc.getTextWidth(line.text)
        y = line.y
        if (x > pageWidth) {
          x = 10
          y += 5
        }
        if (addNextLine) {
          x = 10
          y += 5
        }
      })
      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  splitText (doc: jsPDF, text: string, style: string, x: number, y: number, maxWidth: number): {
    text: string
    x: number
    y: number
  }[] {
    const words = text.split(' ')
    const lines: { text: string, x: number, y: number }[] = []
    let currentLine = ''
    let currentX = x

    doc.setFont('helvetica', style)

    words.forEach((word, index) => {
      const testLine = currentLine + (currentLine ? ' ' : '') + word
      const testWidth = doc.getTextWidth(testLine)
      if (currentX + testWidth > maxWidth && currentLine) {
        lines.push({ text: currentLine, x: currentX, y })
        currentLine = word
        y += 5
        currentX = 10
      } else {
        currentLine = testLine
      }

      if (index === words.length - 1) {
        lines.push({ text: currentLine, x: currentX, y })
      }
    })

    return lines
  }

  async getImageData (url: string): Promise<string> {
    const response = await fetch(url)

    const blob = await response.blob()
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  async loadImage (url: string) {
    if (!url || typeof url !== 'string' || !/^https?:\/\//.test(url)) {
      console.error('Invalid URL provided:', url)
      return null
    }

    let mimeType = 'image/jpeg' // Valor predeterminado

    if (url.endsWith('.png')) {
      mimeType = 'image/png'
    } else if (url.endsWith('.jpeg') || url.endsWith('.jpg')) {
      mimeType = 'image/jpeg'
    }
    const encodedUrl = encodeURIComponent(url)
    const uri = `${baseURl}/file-proxy/get-image?url=${encodedUrl}`
    try {
      const response = await axios.get(uri, {
        responseType: 'arraybuffer',
      })
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      return `data:${mimeType};base64,${base64}`
    } catch (error) {
      console.error('Error loading car photo:', error)
      return null
    }
  }
}
